import './CredentialsComponent.css'
import '../../Theme.css';

import smileyFaceIcon from '../../assets/smiley-face-icon.svg';
import examBoardIcon from '../../assets/exam-board-icon.svg';
import certificateIcon from '../../assets/certificate-icon.svg';
import peopleIcon from '../../assets/people-icon.svg';
import personIcon from '../../assets/person-icon.svg';

import { Image } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function CredentialsComponent(windowWidth) {
    const [containerStyle, setContainerStyle] = useState({
        gap: '120px',
    });

    const [statColStyle, setStatColStyle] = useState({});
    const [sectionHeadingStyle, setSectionHeadingStyle] = useState({});

    useEffect(() => {
        const gapCalculated = (windowWidth.windowWidth - 1440) / 2 + 120;
        const gapResponsive = gapCalculated > 240 ? 240 : gapCalculated < 20 ? 20 : gapCalculated;
        const newValue = {
            gap: `${gapResponsive}px`,
        };
        if (windowWidth < 1440) {
            setSectionHeadingStyle({
                flex: 'none',
            });
            setStatColStyle({
                flexDirection: 'column',
            });
        } else {
            setSectionHeadingStyle({});
            setStatColStyle({
                flexDirection: 'row',
            });
        }
        setContainerStyle(newValue);

    }, [windowWidth]);

    return (
        <div className='credentials-container' style={containerStyle}>
            <div className='credentials-section-heading-container' style={sectionHeadingStyle}>
                <div className='credentials-section-heading-text-container'>
                    <div className='heading-1 primary-text'>
                        <div>Mengapa Percaya Tim Asisten Ilmiahmu?</div>
                    </div>
                    <div className='body-1 primary-text text-justified'>
                        Kami percaya bahwa privasimu penting. Kami tidak terafiliasi institusi manapun, dan sudah melayani TS secara anonim sejak 2017.
                    </div>
                </div>
                <div className='credentials-section-heading-button round-corner-8 double-brand-border brand-fill button clickable'>
                    <Link to='/credentials' className='plain-link body-2 white-text text-center'>
                        Lihat Portofolio Kami
                    </Link>
                </div>
            </div>
            <div className='credentials-statistics-container' style={statColStyle}>
                <div className='credentials-statistics-column-container'>
                    <div className='credentials-statistics-row-container'>
                        <Image className='credentials-statistics-row-icon' src={smileyFaceIcon} alt='Client Repeat Order' />
                        <div className='credentials-statistics-row-details'>
                            <div className='heading-3 primary-text'>73%</div>
                            <div className='body-2 primary-text no-wrap'>Client Repeat Order</div>
                        </div>
                    </div>
                    <div className='credentials-statistics-row-container'>
                        <Image className='credentials-statistics-row-icon' src={examBoardIcon} alt='Annual Jobs Done' />
                        <div className='credentials-statistics-row-details'>
                            <div className='heading-3 primary-text'>3,372</div>
                            <div className='body-2 primary-text no-wrap'>Annual Jobs Done</div>
                        </div>
                    </div>
                </div>
                <div className='credentials-statistics-column-container'>
                    <div className='credentials-statistics-row-container'>
                        <Image className='credentials-statistics-row-icon' src={certificateIcon} alt='Team TOEFL ITP Score' />
                        <div className='credentials-statistics-row-details'>
                            <div className='heading-3 primary-text'>657</div>
                            <div className='body-2 primary-text no-wrap'>Team TOEFL ITP Score</div>
                        </div>
                    </div>
                    <div className='credentials-statistics-row-container'>
                        <Image className='credentials-statistics-row-icon' src={peopleIcon} alt='Team Size' />
                        <div className='credentials-statistics-row-details'>
                            <div className='heading-3 primary-text'>95</div>
                            <div className='body-2 primary-text no-wrap'>Team Size</div>
                        </div>
                    </div>
                    <div className='credentials-statistics-row-container'>
                        <Image className='credentials-statistics-row-icon' src={personIcon} alt='Years of Experience' />
                        <div className='credentials-statistics-row-details'>
                            <div className='heading-3 primary-text'>7</div>
                            <div className='body-2 primary-text no-wrap'>Years of Experience</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CredentialsComponent;