import LandingComponent from "../../components/CredentialsPage/CredentialsLandingComponent";
import LanguageComponent from "../../components/CredentialsPage/CredentialsLanguageComponent";
import PapersComponent from "../../components/CredentialsPage/CredentialsPapersComponent";
import TestimonialComponent from '../../components/CredentialsPage/CredentialsTestimonialComponent';

import { useEffect, useState } from "react";

function CredentialsPage() {

    const [windowWidth, setWindowWidth] = useState(0);

    useEffect(() => { 
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => 
            window.removeEventListener('resize',updateDimensions);
    }, [windowWidth]);
    const updateDimensions = () => {
        const width = window.innerWidth;
        setWindowWidth(width);
    };

    return (
        <div className='white-fill'>
            <LandingComponent windowWidth={windowWidth} />
            <PapersComponent windowWidth={windowWidth} />
            <LanguageComponent windowWidth={windowWidth} />
            <TestimonialComponent windowWidth={windowWidth} />
        </div>
    );
}

export default CredentialsPage;