import './ServicesContentComponent.css';
import '../../Theme.css';

import waIcon from '../../assets/logo-wa.svg';
import payIcon from '../../assets/services-steps-icons/step-boxes-pay.svg';
import formIcon from '../../assets/services-steps-icons/step-boxes-form.svg';
import workIcon from '../../assets/services-steps-icons/step-boxes-work.svg';
import deliverIcon from '../../assets/services-steps-icons/step-boxes-deliver.svg';
import confirmIcon from '../../assets/services-steps-icons/step-boxes-confirm.svg';

import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';

function ContentComponent(windowWidth) {

    // numbering container size
    const [containerForThreeStyle, setContainerForThreeStyle] = useState({
        gap: '32px',
    });
    const [containerForTwoStyle, setContainerForTwoStyle] = useState({
        gap: '32px',
    });

    const [stepBoxStyle, setStepBoxStyle] = useState({
        padding: '24px 32px',
        gap: '16px',
    });

    const [stepBoxHeading, setStepBoxHeading] = useState({
        gap: '16px',
    });

    const [stepBoxIcon, setStepBoxIcon] = useState({
        width: '64px',
        height: '64px',
    });

    const [numberingContainerSize, setNumberingContainerSize] = useState({
        width: '40px',
        height: '40px',
        left: '-20px',
        top: '-10px',
    });

    useEffect(() => {
        const width = windowWidth.windowWidth;
        const scaleFactor = width / 1440;
        const gapCalculated = (width - 1440) / 2 + 32;
        const gapResponsive = gapCalculated > 64 ? 64 : gapCalculated < 20 ? 20 : gapCalculated;
        setContainerForThreeStyle({
            gap: `${gapResponsive}px`,
        });
        const paddingCalculated = (width - 1440) / 2 + 80;
        const paddingResponsive = paddingCalculated > 160 ? 160 : paddingCalculated < 0 ? 0 : paddingCalculated;
        const newValue = {
            gap: `${gapResponsive}px`,
            padding: `0px ${paddingResponsive}px`
        };
        setContainerForTwoStyle(newValue);
        
        setStepBoxStyle({
            // width: `${stepBoxWidth}px`,
            // padding: `${24*scaleFactor}px ${32*scaleFactor}px`,
            // padding: '24px 32px',
            gap: `${16*scaleFactor}px`,
            // gap: '16px'
        });
        setStepBoxHeading({
            gap: `${16*scaleFactor}px`,
        });
        setStepBoxIcon({
            width: `${64*scaleFactor}px`,
            height: `${64*scaleFactor}px`,
        });

        const numberingSize = width / 1440 * 40;
        const numberingLeft = -1 * numberingSize / 2;
        const numberingTop = -1 * numberingSize / 4;

        setNumberingContainerSize({
            width: `${numberingSize}px`,
            height: `${numberingSize}px`,
            left: `${numberingLeft}px`,
            top: `${numberingTop}px`,
        });

    }, [windowWidth]);

    const stepContent = [
        {key: 'form', imageSource: formIcon, title: 'Isi Form & Konfirmasi Jasa', content: 'Klien menghubungi Tim Asisten Ilmiahmu untuk negosiasi layanan jasa yang diinginkan.', specialStyling: {flex: '1 0 33%'}},
        {key: 'pay', imageSource: payIcon, title: 'Pembayaran DP', content: 'Setelah terjadi kesepakatan, klien membayar DP dan mengirimkan bukti transfer.', specialStyling: {flex: '1 0 33%'}},
        {key: 'work', imageSource: workIcon, title: 'Pengerjaan', content: 'Pekerjaan dan deadline mulai terhitung setelah pembayaran DP.', specialStyling: {flex: '1 0 24%'}},
        {key: 'confirm', imageSource: confirmIcon, title: 'Konfirmasi Selesai & Pelunasan', content: 'Bila pekerjaan sudah selesai, Tim Asisten Ilmiahmu akan menghubungi klien untuk memberikan bukti hasil pekerjaan dan Rincian Final untuk dilunasi.'},
        {key: 'deliver', imageSource: deliverIcon, title: 'Pengiriman Produk Akhir', content: 'Tim Asisten Ilmiahmu akan mengirimkan dokumen hasil pekerjaan kepada klien.', specialStyling: {flex: '1 0 35%'}}
    ];
    const topStepBoxes = [];
    const bottomStepBoxes = [];
    
    for (let i = 0; i < stepContent.length; i++) {
        const contentBox = (
            <div key={stepContent[i].key} className='flex' style={stepContent[i].specialStyling}>
                <div className='content-steps-box round-corner-16 lighter-3-fill relative' style={stepBoxStyle}>
                    <div className='content-step-box-heading-container'  style={stepBoxHeading}>
                        <div className='content-steps-box-heading-icon-container'>
                            <Image className='content-step-box-heading-icon' src={stepContent[i].imageSource} style={stepBoxIcon} />
                        </div>
                        <div className='heading-3 primary-text'>{stepContent[i].title}</div>
                    </div>
                    <div className='body-1 primary-text align-stretch' style={{fontSize: '1.6vw'}}>{stepContent[i].content}</div>
                    <div className='content-steps-box-numbering-container brand-fill round-corner-200' style={numberingContainerSize}>
                        <div className='heading-2 white-text text-center'>{i+1}</div>
                    </div>
                </div>
            </div>
        );

        if (i < 3) {
            topStepBoxes.push(contentBox);
                // <ContentBox key={stepContent[i].key} imageSource={stepContent[i].imageSource} content={stepContent[i].content} index={i} />
        } else {
            bottomStepBoxes.push(contentBox);
                // <ContentBox key={stepContent[i].key} imageSource={stepContent[i].imageSource} content={stepContent[i].content} index={i} />
        }
    }

    return (
        <div className='content-container'>
            <div className='content-header-container'>
                <div className='heading-1 primary-text text-center'>Pemesanan</div>
                <Link to='https://wa.me/6281945122333/' className='content-header-wa-container plain-link'>
                    <Image className='content-header-wa-icon' src={waIcon} alt='WhatsApp' />
                    <div className='content-header-wa-button brand-fill round-corner-8 button clickable'>
                        <div className='body-2 white-text text-center'>Hubungi Tim</div>
                    </div>
                </Link>
            </div>
            <div className='content-steps-container'>
                <div className='content-steps-3-box-container' style={containerForThreeStyle}>
                    {topStepBoxes}
                </div>
                <div className='content-steps-2-box-container' style={containerForTwoStyle}>
                    {bottomStepBoxes}
                </div>
            </div>
        </div>
    );
}

export default ContentComponent;