import './NavbarComponent.css';
import '../Theme.css';

import logo from '../assets/logo-horizontal-text.svg';

import { Image } from 'react-bootstrap';
import { Link, Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
// import GoogleLoginButton from './Auth/GoogleLoginButton';

function NavbarComponent({windowWidth, currentRoute}) {
    const [loginStatus, setLoginStatus] = useState('loggedOut');
    const [currentUser, setCurrentUser] = useState({});

    const pageItems = [
        {id: 1, pageRoute: 'services', pageText: 'Pilihan Jasa', enabled: true},
        {id: 2, pageRoute: 'mastery', pageText: 'Kelas Materi', enabled: false},
        {id: 3, pageRoute: 'credentials', pageText: 'Kredensial', enabled: true},
        {id: 4, pageRoute: 'blog', pageText: 'Blog', enabled: false},
    ];

    const [pagesContainerStyle, setPagesContainerStyle] = useState({});
    const [imageSize, setImageSize] = useState({
        width: '218px',
        height: '60px',
        // flexShrink: 0,
    });
    const [buttonStyle, setButtonStyle] = useState({
        gap: '16px',
        padding: '16px 32px',
        fontSize: '1.05vw',
    });
    const [avatarStyle, setAvatarStyle] =  useState({
        width: '32px',
        borderRadius: '50%',
    });

    useEffect(() => { 
        const width = windowWidth;
        const gapCalculated = (width - 1440) / 2 + 64;
        const gapResponsive = gapCalculated > 128 ? 128 : gapCalculated < 50 ? 50 : gapCalculated;
        const newValue = {
            gap: `${gapResponsive}px`,
        };
        setPagesContainerStyle(newValue);
        const scaleFactor = width / 1440;
        setImageSize({
            width: `${218*scaleFactor}px`,
            height: `${80*scaleFactor}px`,
            // flexShrink: 0,
        });
        setButtonStyle({
            gap: `${16*scaleFactor}px`,
            padding: `${16*scaleFactor}px ${32*scaleFactor}px`,
            // fontSize: '1.05vw',
        });
        setAvatarStyle({
            width: `${32*scaleFactor}px`,
            borderRadius: '50%',
        });
        
        // setNavPages({
        //     width: `${69*scaleFactor}px`,
        //     height: `${24*scaleFactor}px`,
        // });
    }, [windowWidth, currentRoute]);


    return (
        <>
            <nav className='nav-bar-container white-fill box-shadow-10'>
                <Link to="/home">
                    <Image className='nav-bar-logo-image' src={logo} alt='Asisten Ilmiahmu' style={imageSize} />
                </Link>
                <div className='nav-pages-container' style={pagesContainerStyle}>
                    {pageItems.map(item => (
                        <div key={item.pageRoute}>
                            {item.enabled && 
                            // onClick={() => handlePageClick(item.pageRoute)}
                                <div className='nav-pages-hover'>
                                    {currentRoute === '/' + item.pageRoute && 
                                        <Link to={'/' + item.pageRoute} className='plain-link no-wrap body-2 brand-text'>{item.pageText}</Link>
                                    }
                                    {!(currentRoute === '/' + item.pageRoute) && 
                                        <Link to={'/' + item.pageRoute} className='plain-link no-wrap body-2 primary-text'>{item.pageText}</Link>
                                    }
                                </div>
                            }
                            {!item.enabled &&
                                <Tooltip title={<h2>Coming soon!</h2>} arrow>
                                    <div className='nav-pages-no-hover'>
                                        <div className='plain-link no-wrap body-2 secondary-text'>{item.pageText}</div>
                                    </div>
                                </Tooltip>
                            }
                        </div>
                    ))}
                </div>
                <div className='nav-bar-buttons'>
                    {/* {loginStatus === 'loggedOut' && 
                        <div>coming soon</div>
                        // <GoogleLoginButton updateLoginStatus={setLoginStatus} updateUserStatus={setCurrentUser} />
                    }
                    {loginStatus === 'loggedIn' && 
                        <div className='signup-button brand-fill body-2 white-text round-corner-8 no-wrap button' style={buttonStyle}>
                            <Image style={avatarStyle} src={currentUser.picture} alt='Avatar' />
                            <div>Account</div>
                        </div>
                    } */}
                    
                    <Tooltip title={<h2>Coming soon!</h2>} arrow>
                        <div className='signup-button brand-fill body-2 white-text round-corner-8 no-wrap button' style={buttonStyle}>Daftar Akun</div>
                    </Tooltip>
                </div>
            </nav>
            <Outlet />
        </>
    );
}

export default NavbarComponent;