import './ServicesComponent.css';

import asistensiPublikasiIcon from '../../assets/services-icons/asistensi-publikasi-icon.svg';
import translateKeIndo from '../../assets/services-icons/translate-ke-indo.svg';
import makalahReferatPaperIcon from '../../assets/services-icons/makalah-referat-paper-icon.svg';
import radialFadeLeft from '../../assets/radial-fade-l.svg';
import radialFadeRight from '../../assets/radial-fade-r.svg';

import { Image } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

function ServicesComponent(windowWidth) {
    const [containerStyle, setContainerStyle] = useState({
        gap: '56px',
    });

    const [cardStyle, setCardStyle] = useState({
        width: '0px',
        height: '0px',
        boxSizing: 'border-box'
    });

    useEffect(() => {
        const width = windowWidth.windowWidth;
        const gapCalculated = (width - 1440) / 2 + 56;
        const gapResponsive = gapCalculated > 112 ? 112 : gapCalculated < 20 ? 20 : gapCalculated;
        const newValue = {
            gap: `${gapResponsive}px`,
        };
        setContainerStyle(newValue);
        const cardWidth = (width - 240 - (2 * gapResponsive)) / 3;
        setCardStyle({
            width: `${cardWidth}px`
        })
    }, [windowWidth]);

    const typeFilters = [
        {key: 'all', text: 'Semua'},
        {key: 'translate', text: 'Penerjemahan'},
        {key: 'writing', text: 'Penulisan'},
        {key: 'design', text: 'Desain'},
        {key: 'data', text: 'Data'},
        {key: 'consult', text: 'Konsultasi'},
    ];

    const startItems = [
        {id: 1, type: 'translate', title: 'Terjemahkan B. Inggris ke B. Indo', description: 'Terjemahan berkualitas, dilakukan manual dan bukan dengan software otomatis', buttonLabel: 'Mulai Terjemahkan', icon: translateKeIndo},
        {id: 2, type: 'writing', title: 'Asistensi Publikasi', description: 'Bantuan Publikasi dari 0 sampai publikasi jurnal', buttonLabel: 'Kawal Publikasiku', icon: asistensiPublikasiIcon},
        {id: 3, type: 'writing', title: 'Makalah, Referat, Paper', description: 'Menyusun tulisan ilmiah sesuai instruksimu', buttonLabel: 'Booking Tim', icon: makalahReferatPaperIcon},
    ];

    const handleActionClick = (title) => {
        const actionLink = 'https://api.whatsapp.com/send/?phone=6281945122333&text=' + encodeURIComponent('Halo Tim Asisten Ilmiahmu, saya perlu bantuan untuk *' + title.toUpperCase() + '*. Apakah bisa dibantu? ') + '%E2%98%BA%EF%B8%8F';
        window.location.href = actionLink;
    };

    return (
        <div className='services-container'>
            <Image className='radial-fade-left' src={radialFadeLeft} alt='Radial Fade Left' />
            <Image className='radial-fade-right' src={radialFadeRight} alt='Radial Fade Right' />

            <div className='services-content-container'>
                <div className='services-section-heading-container'>
                    <div className='heading-1 primary-text text-center'>
                        Pilihan Jasa Asisten Ilmiahmmu
                    </div>
                    <div className='body-1 primary-text text-center'>
                        Asisten Ilmiahmu memberikan jasa asistensi sesuai kebutuhanmu dengan mengedepankan Konsistensi Kualitas, Komitmen Deadline, dan Privasimu.
                    </div>
                </div>
                <div className='services-options-container'>
                    <div className='services-cards-container' style={containerStyle}>
                    {startItems.map(item => (
                        <div key={item.id} className='services-card round-corner-40 single-lighter-3-border white-fill box-shadow-05 card' style={cardStyle}>
                            <div className='services-service-card-heading-container'>
                                <div className='services-service-card-type-container'>
                                    <div className='heading-2 primary-text text-center'>{typeFilters.find(x => x.key === item.type).text}</div>
                                </div>
                                <Image className='services-service-card-icon' src={item.icon} alt={item.title} />
                                <div className='services-service-card-name-container'>
                                    <div className='heading-4 primary-text text-center'>{item.title}</div>
                                </div>
                            </div>
                            <div className='services-service-card-description-container'>
                                <div className='body-2 primary-text text-center'>{item.description}</div>
                                <div className='services-service-card-cta-button round-corner-8 single-lighter-3-border white-fill box-shadow-05 button clickable' onClick={() => handleActionClick(item.title)} > 
                                    <div className='body-2 brand-text text-center'>{item.buttonLabel}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                    </div>
                    <div className='services-options-cta-button brand-fill round-corner-8 double-brand-border button clickable'>
                        <Link to='/services' className='plain-link body-2 white-text text-center'>Pilihan Jasa</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServicesComponent;