import './CredentialsTestimonialComponent.css';

import waIcon from '../../assets/logo-wa.svg';
import testimonial1 from '../../assets/credentials-testimonials/testimonial-1.svg';
import testimonial2 from '../../assets/credentials-testimonials/testimonial-2.svg';
import testimonial3 from '../../assets/credentials-testimonials/testimonial-3.svg';
import testimonial4 from '../../assets/credentials-testimonials/testimonial-4.svg';
import testimonial5 from '../../assets/credentials-testimonials/testimonial-5.svg';
import testimonial6 from '../../assets/credentials-testimonials/testimonial-6.svg';
import testimonial7 from '../../assets/credentials-testimonials/testimonial-7.svg';
import testimonial8 from '../../assets/credentials-testimonials/testimonial-8.svg';
import testimonial9 from '../../assets/credentials-testimonials/testimonial-9.svg';
import testimonial10 from '../../assets/credentials-testimonials/testimonial-10.svg';
import testimonial11 from '../../assets/credentials-testimonials/testimonial-11.svg';
import testimonial12 from '../../assets/credentials-testimonials/testimonial-12.svg';

import { Link } from 'react-router-dom';
import { Image } from 'react-bootstrap';
import { useEffect, useState } from 'react';

export default function TestimonialComponent(windowWidth) {
    const [containerStyle, setContainerStyle] = useState({
        height: '856px',
        gap: '120px',
        marginBottom: '-200px',
    });

    const items = [
        {id: 1, name: 'Item 1', imageSource: testimonial1}, 
        {id: 2, name: 'Item 2', imageSource: testimonial2},
        {id: 3, name: 'Item 3', imageSource: testimonial3},
        {id: 4, name: 'Item 4', imageSource: testimonial4},
        {id: 5, name: 'Item 5', imageSource: testimonial5},
        {id: 6, name: 'Item 6', imageSource: testimonial6},
        {id: 7, name: 'Item 7', imageSource: testimonial7},
        {id: 8, name: 'Item 8', imageSource: testimonial8},
        {id: 9, name: 'Item 9', imageSource: testimonial9},
        {id: 10, name: 'Item 10', imageSource: testimonial10},
        {id: 11, name: 'Item 11', imageSource: testimonial11},
        {id: 12, name: 'Item 12', imageSource: testimonial12},
    ];

    const [slideOutItem, setSlideOutItem] = useState(null);
    const [slideDirection, setSlideDirection] = useState('');
    const [activeItem, setActiveItem] = useState(items[0]);

    useEffect(() => {
        const width = windowWidth.windowWidth;
        const gapCalculated = (width - 1440) / 2 + 120;
        const gapResponsive = gapCalculated > 240 ? 240 : gapCalculated < 40 ? 40 : gapCalculated;
        const height = 856 * (width - 240)/1200;
        const marginBottom = -454 * width / 1440;
        const newValue = {
            height: `${height}px`,
            gap: `${gapResponsive}px`,
            marginBottom: `${marginBottom}px`, // 200 is arbitrary, may need to scale since footer wave is scaled
        };
        setContainerStyle(newValue);
    }, [windowWidth]);

    const handleSlide = (item) => {
        if (item.id === activeItem.id) {
            return;
        }

        const leftMostId = items[0].id;
        const rightMostId = items[items.length - 1].id;
        if (activeItem.id === rightMostId && item.id === leftMostId) {
            setSlideDirection('right');
        } else if (activeItem.id === leftMostId && item.id === rightMostId) {
            setSlideDirection('left');
        } else {
            console.warn('old', activeItem.id, 'new', item.id)
            if (item.id > activeItem.id) {
                console.warn('right')
                setSlideDirection('right');
            } else {
                console.warn('left')
                setSlideDirection('left');
            }
        }
        setTimeout(() => {
            setSlideOutItem(activeItem);
            setActiveItem(item);
        }, 150);
    }

    return (
        <div className='credentials-testimonial-wrapper'>
            <div className='credentials-testimonial-container' style={containerStyle}>
                <div className='credentials-testimonial-section-heading-container primary-text'>
                    <div className='credentials-testimonial-section-heading-text-container primary-text'>
                        <div className='heading-1'>Apa Kata Mereka</div>
                        <div className='body-1'>
                            Tim Asisten Ilmiahmu senantiasa bersyukur atas besarnya kepercayaan klien akan pekerjaan kami. Berikut cuplikan testimoni klien kami.
                        </div>
                    </div>
                    <Link to='https://wa.me/6281945122333/' className='credentials-testimonial-section-heading-wa-container plain-link'>
                        <Image className='credentials-testimonial-section-heading-wa-icon' src={waIcon} alt='WhatsApp' />
                        <div className='credentials-testimonial-section-heading-wa-button brand-fill round-corner-8 button clickable'>
                            <div className='body-2 white-text text-center'>Hubungi Tim</div>
                        </div>
                    </Link>
                </div>
                <div className='credentials-testimonial-carousel-container'>
                    <div className='credentials-testimonial-carousel-dots-container'>
                        {items.map(item => (
                            <div key={item.id} className={`credentials-testimonial-carousel-dot ${item.id === activeItem.id ? 'brand-fill' : ''} double-brand-border`} onClick={() => handleSlide(item)}></div>
                        ))}
                    </div>
                    <div className='credentials-testimonial-testimonial-container round-corner-16'>
                        {items.map(item => (
                            <Image key={item.id} className={`credentials-testimonial-testimonial-item fade-in-out ${item.id === activeItem.id ? 'active' : ''} ${slideOutItem && item.id === slideOutItem.id ? 'slide-out' : ''} ${!slideDirection ? '' : slideDirection === 'left' ? 'left' : 'right'}`} src={activeItem.imageSource} style={{maxHeight: '100%'}} alt='Testimonial' />
                        ))}
                        {/* <Image className={`fade-in-out ${item.id === activeItem.id ? 'active' : ''}`} src={activeItem.imageSource} style={{width: '100%'}} alt='Testimonial' /> */}
                    </div>
                </div>
            </div>
        </div>
    );
}