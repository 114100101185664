import { useRef, useEffect } from 'react';

function ThumbnailImage({ src, width, height }) {
  const canvasRef = useRef(null);

  useEffect(() => {
    // console.warn(src, width, height);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    const image = new Image();
    image.src = src;

    image.onload = () => {
      // Calculate the aspect ratio
      const aspectRatio = image.width / image.height;

      // Calculate the new dimensions while preserving the aspect ratio
      let newWidth = width;
      let newHeight = height;
      if (width && !height) {
        newHeight = width / aspectRatio;
      } else if (!width && height) {
        newWidth = height * aspectRatio;
      } else if (width && height) {
        if (width / height > aspectRatio) {
          newWidth = height * aspectRatio;
        } else {
          newHeight = width / aspectRatio;
        }
      }

      // Set the canvas size to the new dimensions
      canvas.width = newWidth;
      canvas.height = newHeight;

      // Draw the image on the canvas
      ctx.drawImage(image, 0, 0, newWidth, newHeight);
    };
  }, [src, width, height]);

  return (<canvas ref={canvasRef} />);
}

export default ThumbnailImage;