import './TestimonialComponent.css';
import '../../Theme.css';

import homeTestimonialWave from '../../assets/home-testimonial-wave.svg';
import testimonialPrompt from '../../assets/home-testimonial-prompt.svg';
import testimonialReplies from '../../assets/home-testimonial-replies.svg';

import { Image } from 'react-bootstrap';
import VerticalAutoScroll from './TestimonialCarousel';

function TestimonialComponent() {
    const replies = <Image className='testimonial-testimony-replies-child' src={testimonialReplies} alt='Testimonial Replies' />;

    return (
        <div className='testimonial-container'>
            <Image className='home-testimonial-wave' src={homeTestimonialWave} alt='Home Testimonial Wave' />
            <div className='testimonial-content-container'>
                <div className='testimonial-section-heading-container'>
                    <div className='heading-1 primary-text text-center'>Review Klien kami</div>
                </div>
                <div className='testimonial-testimony-container'>
                    <div className='testimonial-testimony-prompt'>
                        <Image className='testimonial-testimony-prompt-child' src={testimonialPrompt} alt='Testimonial Prompt' />
                    </div>
                    {/* <VerticalAutoScroll /> */}
                    <div className='testimonial-testimony-replies'>
                        <div className='testimonial-testimony-replies-content'>
                            <Image className='testimonial-testimony-replies-child'  src={testimonialReplies} alt='Testimonial Replies' />
                            {/* <Image className='testimonial-testimony-replies-child' src={testimonialReplies} alt='Testimonial Replies' /> */}
                        </div>
                    </div>
                    
                </div>
            </div>
        
        </div>
    );
}

export default TestimonialComponent;