import './BlogComponent.css'
import '../../Theme.css';

import paperOnPaper from '../../assets/paper-on-paper.png';
import grammarInBook from '../../assets/grammar-on-paper.png';
import laptopOnTable from '../../assets/laptop-on-table.png';

import { Image } from 'react-bootstrap';
import { Tooltip } from '@mui/material';
import { useState, useEffect } from 'react';


function BlogComponent(windowWidth) {
    const [containerStyle, setContainerStyle] = useState({
        gap: '64px',
    });

    const [cardImageStyle, setCardImageStyle] = useState({
        width: '0px',
        height: '0px'
    });

    const[innerArticleCardStyle, setInnerArticleCardStyle] = useState({
        width: '0px',
        height: '218px',
        boxSizing: 'border-box'
    });

    useEffect(() => {
        const width = windowWidth.windowWidth;
        const gapCalculated = (width - 1440) / 2 + 64;
        const gapResponsive = gapCalculated > 128 ? 128 : gapCalculated < 20 ? 20 : gapCalculated;
        const newValue = {
            gap: `${gapResponsive}px`,
        };

        const imageWidth = (width - 240 - (2 * gapResponsive)) / 3;
        const imageHeight = imageWidth * 286 / 368;

        setContainerStyle(newValue);
        setCardImageStyle({
            width: `${imageWidth}px`,
            height: `${imageHeight}px`,
        })

        let cardHeight = 218;
        if (width < 1440) {
            cardHeight = 218 * 1.05;
        }
        setInnerArticleCardStyle({
            width: `${imageWidth * 0.8}px`,
            height: `${cardHeight}px`,
            boxSizing: 'border-box'
        })
    }, [windowWidth]);

    const articleCards = [];
    const cardTitles = [
        {id: 1, title: 'Publikasi 101', image: paperOnPaper},
        {id: 2, title: 'Apa sih pentingnya grammar?', image: grammarInBook},
        {id: 3, title: 'Menghindari Plagiarisme', image: laptopOnTable},
    ];
    for (let i = 0; i < cardTitles.length; i++) {
        articleCards.push(
            <div key={cardTitles[i].id} className='blog-article-card-container' >
                <div className='round-corner-8'>
                <Image style={cardImageStyle} src={cardTitles[i].image} alt={cardTitles[i].title} />
                </div>
                <div className='blog-article-card-content'>
                    <div className='blog-inner-article-card round-corner-12 single-lighter-3-border white-fill box-shadow-05' style={innerArticleCardStyle}>
                        <div className='blog-inner-article-card-text-container heading-4 primary-text text-center 
                        hide-overflow text-overflow-ellipsis flex-1'>
                            {cardTitles[i].title}
                        </div>
                        <div className='flex flex-vertical-reverse flex-1 full-width'>
                            <Tooltip title={<h2>Coming soon!</h2>} arrow>
                                <div className='blog-inner-article-card-secondary-button round-corner-8 lighter-2-fill box-shadow-05 button'>
                                    <div className='body-2 white-text text-center'>Baca Artikelnya</div>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    return (
        <div className='blog-container'>
            <div className='blog-content-container' >
                <div className='blog-section-heading-container primary-text text-center'>
                    <div className='heading-1'>Informasi Menarikmu</div>
                    {/* <div className='body-1'>
                        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </div> */}
                </div>
                <div className='blog-articles-container'>
                    <div className='blog-articles-cards-container' style={containerStyle}>
                        {articleCards}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BlogComponent