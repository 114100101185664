import './SearchbarComponent.css';
import '../Theme.css';

import { AiOutlineSearch } from 'react-icons/ai';

import { useEffect, useState } from 'react';

const SearchBar = ({ onSearch, containerStyling, inputStyling, placeholder, searchButton = null, queryParamEnabled = false }) => {
    const [searchTerm, setSearchTerm] = useState('');

    const handleInputChange = (event) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);
        onSearch(newSearchTerm);
    };

    useEffect(() => {
        if (queryParamEnabled) {
            // Get the query parameter value from the window location
            const queryParams = new URLSearchParams(window.location.search);
            const paramValue = queryParams.get('search');
        
            // Remove the query parameter from the window location
            queryParams.delete('search');
            const queryParamsEntries = Array.from(queryParams.entries());
            let newUrl = `${window.location.origin}${window.location.pathname}`
            if (queryParamsEntries.length > 0) {
                newUrl = newUrl + `?${queryParams.toString()}`;
            }
            window.history.replaceState({}, '', newUrl);
        
            // Use the query parameter value as needed
            console.log('Query Parameter Value:', paramValue);
            if (paramValue) {
                setSearchTerm(paramValue);
                onSearch(paramValue);
            }
        }
      }, [onSearch, queryParamEnabled]);

    const defaultStyle = {
        fontSize: '1.67vw'
    }


    return (
        <div className={containerStyling}>
            <input
            type="text"
            className={inputStyling}
            placeholder={placeholder}
            value={searchTerm}
            onChange={handleInputChange}
            style={defaultStyle}
            />
            {searchButton ? searchButton : <AiOutlineSearch className='search-icon white-text' />}
        </div>
    
  );
};

export default SearchBar;