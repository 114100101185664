import LandingComponent from "../../components/SplashPage/SplashPageLanding.js";

import { useState, useEffect } from "react";

export default function SplashPage() {

    const [windowWidth, setWindowWidth] = useState(0);

    useEffect(() => { 
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => 
            window.removeEventListener('resize',updateDimensions);
    }, [windowWidth]);
    const updateDimensions = () => {
        const width = window.innerWidth;
        setWindowWidth(width);
    };

    return (
        <div className='dark-linear-fill'>
            <LandingComponent windowWidth={windowWidth} />
        </div>
    );
}