import '../../Theme.css';
import './SplashPageLanding.css';

import splashGraphic from '../../assets/splash-graphic.svg';
import { Image } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Searchbar from '../SearchbarComponent';

function LandingComponent(windowWidth) {

    const [containerStyle, setContainerStyle] = useState({
        gap: '60px',
    });

    const [imageSize, setImageSize] = useState({
        width: '360px',
        height: '360px',
    });

    useEffect(() => {
        const width = windowWidth.windowWidth
        const gapCalculated = (width - 1440) / 2 + 60;
        const gapResponsive = gapCalculated > 120 ? 120 : gapCalculated < 20 ? 20 : gapCalculated;
        setContainerStyle({
            gap: `${gapResponsive}px`,
        });

        const imageWidth = 360 * width / 1440;
        setImageSize({
            width: `${imageWidth}px`,
            height: `${imageWidth}px`
        })

    }, [windowWidth]);

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (searchTerm) => {
        setSearchTerm(searchTerm);
    };

    const searchButton = <Link to={`/services/?search=${searchTerm}`} className='brand-fill round-corner-40 search-button plain-link white-text body-2 button'>Search</Link>;

    return (
        <div className='splash-page-landing-container' style={containerStyle}> 
            <div className='splash-page-landing-heading-container'>
                <div className='splash-page-landing-heading-text-container white-text'>
                    <div className='mega-1'>Error 404</div>
                    <div className='heading-1'>Sebentar ya, dokternya sedang visit pasien</div>
                    <div className='body-1 align-stretch'>Sepertinya halaman yang kamu cari tidak ada. Coba pastikan tidak ada kesalahan pengetikan di URL yang kamu ketik, atau kamu bisa mencari halaman di kolom pencarian di bawah, ya!</div>
                </div>
                <Searchbar containerStyling='landing-search-bar-container full-width' inputStyling='landing-search-bar body-2 white-text primary-fill round-corner-40' placeholder='Temukan solusi masalah ilmiahmu!' searchButton={searchButton} onSearch={handleSearch} />
            </div>
            <Image src={splashGraphic} alt='Graphic' style={imageSize} />
        </div>
    );
}

export default LandingComponent;