import './ServicesListComponent.css';
import '../../Theme.css';

import Searchbar from '../SearchbarComponent';

import { useState, useEffect } from 'react';

import abstrakIcon from '../../assets/services-icons/abstrak-icon.svg';
import analisisDataIcon from '../../assets/services-icons/analisis-data-icon.svg';
import asistensiPublikasiIcon from '../../assets/services-icons/asistensi-publikasi-icon.svg';
import formatIcon from '../../assets/services-icons/format-icon.svg';
import inputDataIcon from '../../assets/services-icons/input-data-icon.svg';
import konsulCallIcon from '../../assets/services-icons/konsul-call-icon.svg';
import konsulChatIcon from '../../assets/services-icons/konsul-chat-icon.svg';
import laporanKasusIcon from '../../assets/services-icons/laporan-kasus-icon.svg';
import makalahReferatPaperIcon from '../../assets/services-icons/makalah-referat-paper-icon.svg';
import posterIcon from '../../assets/services-icons/poster-icon.svg';
import powerpointIcon from '../../assets/services-icons/powerpoint-icon.svg';
import proofreadingEditingIcon from '../../assets/services-icons/proofreading-editing-icon.svg';
import proposalCriticalAppraisalIcon from '../../assets/services-icons/proposal-critical-appraisal-icon.svg';
import searchDokumenIcon from '../../assets/services-icons/search-dokumen-icon.svg';
import translateKeEng from '../../assets/services-icons/translate-ke-eng.svg';
import translateKeIndo from '../../assets/services-icons/translate-ke-indo.svg';

import { Image } from 'react-bootstrap';


export default function ListComponent(windowWidth) {
    const [wrapperStyle, setWrapperStyle] = useState({
        marginBottom: '-450px',
    });
    const [optionsContainerStyle, setOptionsContainerStyle] = useState({
        gridGap: '56px',
    });
    const [serviceCardHeading, setServiceCardHeading] = useState({});

    const startItems = [
        {id: 1,type: 'writing', title: 'Asistensi Publikasi', description: 'Bantuan Publikasi dari 0 sampai publikasi jurnal', buttonLabel: 'Kawal Publikasiku', icon: asistensiPublikasiIcon},
        {id: 2,type: 'writing', title: 'Laporan Kasus', description: 'Menyusun laporan kasus baku menggunakan kelengkapan data pasienmu', buttonLabel: 'Susunkan Laporan', icon: laporanKasusIcon},
        {id: 3,type: 'design', title: 'PowerPoint', description: 'Menyajikan slide PowerPoint ilustratif untuk kebutuhan presentasimu', buttonLabel: 'Sajikan PowerPoint', icon: powerpointIcon},
        {id: 4,type: 'writing', title: 'Proposal, Critical Appraisal', description: 'Merancang proposal dan tinjauan kritis sesuai arahanmu', buttonLabel: 'Mulai Rancang', icon: proposalCriticalAppraisalIcon},
        {id: 5,type: 'writing', title: 'Makalah, Referat, Paper', description: 'Menyusun tulisan ilmiah sesuai instruksimu', buttonLabel: 'Booking Tim', icon: makalahReferatPaperIcon},
        {id: 6,type: 'design', title: 'Poster', description: 'Mewujudkan karya poster estetik ilmiah sesuai arahanmu', buttonLabel: 'Wujudkan Poster', icon: posterIcon},
        {id: 7,type: 'translate', title: 'Translate Indonesian to English', description: 'Quality translation, done manually and not through automated softwares', buttonLabel: 'Start Translating', icon: translateKeEng},
        {id: 8,type: 'writing', title: 'Formatting Paper', description: 'Penyesuaian format paper ilmiah sesuai kebutuhanmu', buttonLabel: 'Lakukan Formatting', icon: formatIcon},
        {id: 9,type: 'translate', title: 'Terjemahkan B. Inggris ke B. Indo', description: 'Terjemahan berkualitas, dilakukan manual dan bukan dengan software otomatis', buttonLabel: 'Mulai Terjemahkan', icon: translateKeIndo},
        {id: 10,type: 'writing', title: 'Pembuatan Abstrak', description: 'Menciptakan abstrak singkat, padat, jelas, dari full-article milikmu', buttonLabel: 'Ciptakan Abstrak', icon: abstrakIcon},
        {id: 11,type: 'data', title: 'Analisis Data', description: 'Proses analisis data sesuai jenis data dan kebutuhan penelitianmu', buttonLabel: 'Lakukan Analisis', icon: analisisDataIcon},
        {id: 12,type: 'writing', title: 'Proofreading & Editing', description: 'Menyempurnakan tulisanmu dengan anti plagiarisme, disesuaikan dengan tata bahasa baku', buttonLabel: 'Sempurnakan Tulisan', icon: proofreadingEditingIcon},
        {id: 13,type: 'data', title: 'Pencarian Dokumen', description: 'Menemukan sumber pustaka sesuai kebutuhanmu', buttonLabel: 'Carikan Dokumen', icon: searchDokumenIcon},
        {id: 14,type: 'consult', title: 'Call', description: 'Bimbingan privat call langsung dengan ahli, menemukan solusi masalahmu', buttonLabel: 'Temukan Solusimu', icon: konsulCallIcon},
        {id: 15,type: 'data', title: 'Input Data', description: 'Memasukkan data sesuai kebutuhan ilmiahmu', buttonLabel: 'Masukkan Data', icon: inputDataIcon},
        {id: 16,type: 'consult', title: 'Chat', description: 'Bimbingan privat chatting dengan ahli, menemukan solusi masalahmu', buttonLabel: 'Temukan Solusimu', icon: konsulChatIcon}
    ]

    useEffect(() => {
        const width = windowWidth.windowWidth;
        if (width < 1090) {
            setServiceCardHeading({
                fontSize: '2vw',
            });
        } else {
            setServiceCardHeading({
                fontSize: '2.22vw',
            });
        }
        const gapCalculated = (width - 1440) / 2 + 56;
        console.warn(gapCalculated);
        const gapResponsive = gapCalculated > 112 ? 112 : gapCalculated < 20 ? 20 : gapCalculated;
        setOptionsContainerStyle({
            gridGap: `${gapResponsive}px`,
        });

        const wrapperMarginBottom = -1 *(width / 1440) * 450;
        setWrapperStyle({
            marginBottom: `${wrapperMarginBottom}px`
        });
    }, [windowWidth]);

    const [currentFilter, setFilter] = useState('all');
    const handleFilterClick = (useFilter) => {
        setFilter(useFilter);
        let filteredItems = startItems;
        if (useFilter !== 'all') {
            filteredItems = filteredItems.filter(item =>
                item.type === useFilter
            );
        }
        if (searchTerm) {
            filteredItems = filteredItems.filter(item =>
                item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.description.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }
        setItems(filteredItems);
    }

    const typeFilters = [
        {key: 'all', text: 'Semua'},
        {key: 'translate', text: 'Penerjemahan'},
        {key: 'writing', text: 'Penulisan'},
        {key: 'design', text: 'Desain'},
        {key: 'data', text: 'Data'},
        {key: 'consult', text: 'Konsultasi'},
    ];

    const filterButtons = []
    typeFilters.forEach(x => {
        filterButtons.push(
        <div key={x.key} className={`services-list-filter-button round-corner-40 body-1 clickable ${currentFilter === x.key ? 'brand-fill white-text' : 'lighter-3-fill primary-text'}`} onClick={() => handleFilterClick(x.key)}>
            {x.text}
        </div>
        );
    });

    const [items, setItems] = useState(startItems);
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (searchTerm) => {
        setSearchTerm(searchTerm);
        let filteredItems = startItems;
        if (currentFilter !== 'all') {
            filteredItems = filteredItems.filter(item =>
                item.type === currentFilter
            );
        }
        if (searchTerm) {
            filteredItems = filteredItems.filter(item =>
                item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.description.toLowerCase().includes(searchTerm.toLowerCase())
              );
        }
        setItems(filteredItems);
    };

    const handleActionClick = (title) => {
        const actionLink = 'https://api.whatsapp.com/send/?phone=6281945122333&text=' + encodeURIComponent('Halo Tim Asisten Ilmiahmu, saya perlu bantuan untuk *' + title.toUpperCase() + '*. Apakah bisa dibantu? ') + '%E2%98%BA%EF%B8%8F';
        window.location.href = actionLink;
    };

    return (
        <div className='services-list-wrapper' style={wrapperStyle}>
            {/* ref={serviceslistContainerRef} */}
            <div className='services-list-container'>
                <Searchbar containerStyling='services-page-search-bar-container' inputStyling='services-page-search-bar white-text round-corner-40 primary-fill clickable' placeholder='Temukan solusi masalah ilmiahmu!' onSearch={handleSearch} queryParamEnabled={true} />
                <div className='heading-1 primary-text text-center' style={{zIndex: '2'}}>List of Services</div>
                <div className='services-list-filter-container round-corner-40 lighter-3-fill' style={{zIndex: '2'}}>
                    {filterButtons}
                </div>
                <div className='services-page-options-container' style={optionsContainerStyle}>
                    {items.map(item => (
                        <div key={item.id} className='services-service-card round-corner-40 single-lighter-3-border white-fill box-shadow-05 card'>
                            <div className='services-service-card-heading-container'>
                                <div className='services-service-card-type-container'>
                                    <div className='heading-2 primary-text text-center' style={serviceCardHeading}>{typeFilters.find(x => x.key === item.type).text}</div>
                                </div>
                                <Image className='services-service-card-icon' src={item.icon} alt={item.title} />
                                {/* <div className=>
                                    {item.icon}
                                </div> */}
                                <div className='services-service-card-name-container'>
                                    <div className='heading-4 primary-text text-center'>{item.title}</div>
                                </div>
                            </div>
                            <div className='services-service-card-description-container'>
                                <div className='body-2 primary-text text-center'>{item.description}</div>
                                <div className='services-service-card-cta-button round-corner-8 single-lighter-3-border white-fill box-shadow-05 button clickable' onClick={() => handleActionClick(item.title)} > 
                                    <div className='body-2 brand-text text-center'>{item.buttonLabel}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                
            </div>
        </div>
        
        
    );
};