import ContentComponent from "../../components/ServicesPage/ServicesContentComponent";
import LandingComponent from "../../components/ServicesPage/ServicesLandingComponent";
import ListComponent from "../../components/ServicesPage/ServicesListComponent";

import { useEffect, useState } from "react";

export default function ServicesPage() {

    const [windowWidth, setWindowWidth] = useState(0);

    useEffect(() => { 
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => 
            window.removeEventListener('resize',updateDimensions);
    }, [windowWidth]);
    const updateDimensions = () => {
        const width = window.innerWidth;
        setWindowWidth(width);
    };

    return (
        <div className='white-to-white-fill'>
            <LandingComponent windowWidth={windowWidth} />
            <ContentComponent windowWidth={windowWidth} />
            <ListComponent windowWidth={windowWidth} />
        </div>
    );
}