import './CredentialsLanguageComponent.css';
import graphicPlaceholder from '../../assets/graphic-placeholder.png';

import leftCert1 from '../../assets/credentials-cert-left/left-cert-1.png';
import leftCert2 from '../../assets/credentials-cert-left/left-cert-2.png';
import leftCert3 from '../../assets/credentials-cert-left/left-cert-3.png';
import leftCert4 from '../../assets/credentials-cert-left/left-cert-4.png';
import leftCert5 from '../../assets/credentials-cert-left/left-cert-5.png';
import leftCert6 from '../../assets/credentials-cert-left/left-cert-6.png';
import leftCert7 from '../../assets/credentials-cert-left/left-cert-7.png';

import rightCert1 from '../../assets/credentials-cert-right/right-cert-1.png';
import rightCert2 from '../../assets/credentials-cert-right/right-cert-2.png';
import rightCert3 from '../../assets/credentials-cert-right/right-cert-3.png';
import rightCert4 from '../../assets/credentials-cert-right/right-cert-4.png';
import rightCert5 from '../../assets/credentials-cert-right/right-cert-5.png';
import rightCert6 from '../../assets/credentials-cert-right/right-cert-6.png';
import rightCert7 from '../../assets/credentials-cert-right/right-cert-7.png';
import rightCert8 from '../../assets/credentials-cert-right/right-cert-8.png';

import { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';


export default function LanguageComponent(windowWidth) {
    const [initial, setInitial] = useState(true);
    const [containerStyle, setContainerStyle] = useState({
        height: '718px',
    });
    const [certContainerStyle, setCertContainerStyle] = useState({
        gap: '16px',
    });
    
    const leftCarousel = [
        leftCert1, leftCert2, leftCert3, leftCert4, leftCert5, leftCert6, leftCert7
    ];

    const rightCarousel = [
        rightCert1, rightCert2, rightCert3, rightCert4, rightCert5, rightCert6, rightCert7, rightCert8
    ];

    const addAnimation = (scrollers) => {
        console.warn('add animation')
        scrollers.forEach((scroller) => {
            // add data-animated="true" to every `.scroller` on the page
            scroller.setAttribute("data-animated", true);

            // Make an array from the elements within `.scroller-inner`
            const scrollerInner = scroller.querySelector(".scroller__inner");
            const scrollerContent = Array.from(scrollerInner.children);

            // For each item in the array, clone it
            // add aria-hidden to it
            // add it into the `.scroller-inner`
            scrollerContent.forEach((item) => {
            const duplicatedItem = item.cloneNode(true);
            duplicatedItem.setAttribute("aria-hidden", true);
            scrollerInner.appendChild(duplicatedItem);
            });
        });
    }

    useEffect(() => {

        const scrollers = document.querySelectorAll(".scroller");

        // If a user hasn't opted in for recuded motion, then we add the animation
        if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
            if (initial) {
                addAnimation(scrollers);
                setInitial(false);
            }
        }

        const width = windowWidth.windowWidth;
        const scaleFactor = width / 1440;

        setContainerStyle({
            // gap: `${24*scaleFactor}px`,
            height: `${718*scaleFactor}px`,
        });
        setCertContainerStyle({
            gap: `${16*scaleFactor}px`,
        });


        
    }, [windowWidth, initial]);

    return (
        <div className='credentials-language-container lighter-3-fill hide-overflow' style={containerStyle}>
            <div className='credentials-language-certificates-container' style={certContainerStyle}>
                <div className='credentials-left-cert-container scroller' data-speed="slow">
                    <div className='scroller__inner'>
                        {leftCarousel.map((item, index) => (
                            <div key={index} className='carousel-card round-corner-20 single-lighter-3-border white-fill box-shadow-05'>
                                <Image src={item} style={{width: '100%'}} />
                            </div>
                        ))}
                    </div>
                </div>
                <div className='credentials-right-cert-container scroller' data-speed="slow" data-direction="down">
                    <div className='scroller__inner'>
                        {rightCarousel.map((item, index) => (
                            <div key={index} className='carousel-card round-corner-20 single-lighter-3-border white-fill box-shadow-05'>
                                <Image src={item} style={{width: '100%'}} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className='credentials-language-text-container primary-text'>
                <div className='heading-1'>Sertifikasi Kami</div>
                <div className='body-1'>
                    Tidak dapat dipungkiri: dunia medis adalah salah satu industri dengan standar dan regulasi terketat. Keterampilan tidak dapat berdiri sendiri, dan lisensi - sertifikasi berperan sangat vital. Berikut beberapa Sertifikasi Kami.
                </div>
            </div>
        </div>
        // <div class="scrolling-image-container" style={scrollingStyle}>
        //     <div class="scrolling-image" style={scrollingImageStyle}></div>
        //     <div>
        //         hello
        //     </div>
        // </div>
    );
}