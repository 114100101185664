import './LandingComponent.css';
import '../../Theme.css';

import { Image } from 'react-bootstrap';
import logoGIF from '../../assets/logo-hi-res.gif';

import { useEffect, useState } from 'react';
import Searchbar from '../SearchbarComponent';
import { Link } from 'react-router-dom';

function LandingComponent(windowWidth) {
    const [landingContainerStyle, setLandingContainerStyle] = useState({
        gap: '120px',
    });

    const [imageSize, setImageSize] = useState({});

    useEffect(() => {
        const width = windowWidth.windowWidth
        const gapCalculated = (width - 1440) / 2 + 120;
        const gapResponsive = gapCalculated > 240 ? 240 : gapCalculated < 20 ? 20 : gapCalculated;
        const newValue = {
            gap: `${gapResponsive}px`,
        };
        const imageWidth = 500 * width / 1440;
        setImageSize({
            width: `${imageWidth}px`,
            height: `${imageWidth}px`
        })

        setLandingContainerStyle(newValue);

    }, [windowWidth]);

    const [searchTerm, setSearchTerm] = useState('');

    const handleSearch = (searchTerm) => {
        setSearchTerm(searchTerm);
    };

    const searchButton = <Link to={`/services/?search=${searchTerm}`} className='brand-fill round-corner-40 search-button plain-link white-text body-2 button'>Search</Link>;

    return (
        <div className='landing-container' style={landingContainerStyle}> 
            <div className='landing-section-heading'>
                <div className='mega-1 full-width'>
                    <div className='primary-text'>Bukan Hanya Konsulen yang Bisa Punya Asisten, PPDS juga Bisa!</div>
                </div>
                <div className='heading-1 primary-text full-width'>
                    Perkenalkan, Asisten Ilmiahmu.
                </div>
                <div className='primary-text body-1 full-width'>
                    Tim Asistensi Ilmiah Independen <span className='brand-text'>Anonim</span> milikmu, siap membantu masalah Ilmiah TS. <span className='brand-text'>Privasi terjamin</span>.
                </div>
                <Searchbar containerStyling='landing-search-bar-container full-width' inputStyling='landing-search-bar body-2 white-text primary-fill round-corner-40 clickable' placeholder='Temukan solusi masalah ilmiahmu!' searchButton={searchButton} onSearch={handleSearch} />
            </div>
            <div>
                <Image className='landing-graphic' src={logoGIF} alt='Asisten Ilmiahmu' style={imageSize}/>
            </div>
        </div>
    );
}

export default LandingComponent;