// import Carousel from '../HorizontalCarouselComponent';
import './CredentialsPapersComponent.css';

import arrowLeft from '../../assets/arrow-left.svg';
import arrowRight from '../../assets/arrow-right.svg';

import { useEffect, useState } from 'react';
import { Image } from 'react-bootstrap';
import ThumbnailImage from '../../components/ThumbnailImageComponent.js';

import cureus from '../../assets/credentials-papers/cureus.png';
import jafes from '../../assets/credentials-papers/jafes.png';
import udayana from '../../assets/credentials-papers/udayana.png';

export default function PapersComponent(windowWidth) {

    const items = [
        {id: 1, name: 'Item 1', imageSource: cureus}, 
        {id: 2, name: 'Item 2', imageSource: jafes},
        {id: 3, name: 'Item 3', imageSource: udayana},
        // {id: 4, name: 'Item 4', imageSource: },
        // {id: 5, name: 'Item 5', imageSource: },
    ];

    const [imageSize, setImageSize] = useState({
        width: 144,
        height: 80
    });

    const [activePaperStyle, setActivePaperStyle] = useState({
        width: '544px',
        height: '672px',
    });

    const [containerStyle, setContainerStyle] = useState({
        gap: '60px',
    });

    const [arrowSize, setArrowSize] = useState({
        width: '30px',
        zIndex: 1,
    });

    useEffect(() => {
        const width = windowWidth.windowWidth;
        const gapCalculated = (width - 1440) / 2 + 60;
        const gapResponsive = gapCalculated > 120 ? 120 : gapCalculated < 20 ? 20 : gapCalculated;
        setContainerStyle({
            gap: `${gapResponsive}px`,
        });

        const scaleFactor = width / 1440;
        setActivePaperStyle({
            width: `${544*scaleFactor}px`,
            height: `${672*scaleFactor}px`,
        });

        setImageSize({
            width: 144 * scaleFactor,
            height: 80 * scaleFactor,
        });

        setArrowSize({
            width: `${30*scaleFactor}px`,
            zIndex: 1,
        });
        
    }, [windowWidth]);

    const [slideOutItem, setSlideOutItem] = useState(null);
    const [slideDirection, setSlideDirection] = useState('');
    const [activeItem, setActiveItem] = useState(items[0]);

    const handleActionClick = (actionLink) => {
        const activeIndex = items.findIndex(x => x.id === activeItem.id);
        if (actionLink === 'left') {
            if (activeIndex === 0) {
                handleSlide(items[items.length - 1], 'left');
            } else {
                handleSlide(items[activeIndex - 1], 'left');
            }
        } else {
            // right
            if (activeIndex === items.length - 1) {
                handleSlide(items[0], 'right');
            } else {
                handleSlide(items[activeIndex + 1], 'right');
            }
        }
    };

    const handleSlide = (item, slideDir = null) => {
        if (item.id === activeItem.id) {
            return;
        }
        if (slideDir) {
            setSlideDirection(slideDir);
        } else {
            const leftMostId = items[0].id;
            const rightMostId = items[items.length - 1].id;
            if (activeItem.id === rightMostId && item.id === leftMostId) {
                setSlideDirection('right');
            } else if (activeItem.id === leftMostId && item.id === rightMostId) {
                setSlideDirection('left');
            } else {
                if (item.id > activeItem.id) {
                    setSlideDirection('right');
                } else {
                    setSlideDirection('left');
                }
            }
        }
        setTimeout(() => {
            setSlideOutItem(activeItem);
            setActiveItem(item);
        }, 100);
    };

    return (
        <div className='credentials-papers-container' style={containerStyle}>
            <div className='credentials-papers-text-and-carousel-container'>
                <div className='credentials-papers-text-container'>
                    <div className='heading-1 primary-text'>Portofolio Publikasi Kami</div>
                    <div className='body-1 primary-text'>
                        Menembus Jurnal Q1 Internasional maupun Sinta Nasional, Tim Asisten Ilmiahmu telah bertahun-tahun menghasilkan dan membantu tujuan publikasi jurnal para sejawat. Berikut beberapa Portofolio Publikasi Kami.
                    </div>
                </div>
                <div className='credentials-papers-carousel-container'>
                {items.map(item => (
                    <div key={item.id} className={item.id === activeItem.id ? 'double-brand-border' : ''} onClick={() => handleSlide(item)}>
                        <ThumbnailImage src={item.imageSource} width={imageSize.width} height={imageSize.height} />
                    </div>
                ))}
                </div>
            </div>
            <div className='credentials-papers-active-paper-container'>
                <Image className='clickable' src={arrowLeft} alt='arrowLeft' style={arrowSize} onClick={() => handleActionClick('left')}/>
                <div className='credentials-papers-image-container' style={activePaperStyle}>
                    {items.map(item => (
                        <Image key={item.id} className={`credentials-papers-image slide-in-out ${item.id === activeItem.id ? 'active' : ''} ${slideOutItem && item.id === slideOutItem.id ? 'slide-out' : ''} ${!slideDirection ? '' : slideDirection === 'left' ? 'left' : 'right'}`} src={activeItem.imageSource} alt='Paper'  />
                    ))}
                </div>
                {/* <Image src={activeItem.imageSource} alt='Paper' style={activePaperStyle} /> */}
                <Image className='clickable' src={arrowRight} alt='arrowRight' style={arrowSize} onClick={() => handleActionClick('right')}/>
            </div>
        </div>
    );
}