import NavbarComponent from "./NavbarComponent";
import FooterComponent from "./FooterComponent";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

function Layout({ children }) {
    const location = useLocation();

    const [windowWidth, setWindowWidth] = useState(0);
    const [currentRoute, setCurrentRoute] = useState(location.pathname);

    useEffect(() => { 
        const useCurrentRoute = location.pathname;
        setCurrentRoute(useCurrentRoute);
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => 
            window.removeEventListener('resize',updateDimensions);
    }, [windowWidth, location.pathname]);

    const updateDimensions = () => {
        const width = window.innerWidth;
        console.log(width);
        setWindowWidth(width);
    };

    return (
        <div>
            <NavbarComponent windowWidth={windowWidth} currentRoute={currentRoute} />
            {/* <SidebarComponent /> */}
            {children}
            <FooterComponent windowWidth={windowWidth} currentRoute={currentRoute} />
        </div>
    );
}

export default Layout;

