function MasteryPage() {
    return (
        <div>
            <h1>Mastery</h1>
            {
                
            }
            {/* <video src="https://youtu.be/uFhd0dvi3f4"></video> */}
            {/* <iframe 
  width="560" 
  height="315" 
  src="https://www.youtube.com/embed/uFhd0dvi3f4" 
  title="YouTube video player" 
  frameBorder="0" 
  allow="accelerometer; 
  autoplay; 
  clipboard-write; 
  encrypted-media; 
  gyroscope; 
  picture-in-picture; 
  web-share" allowFullScreen></iframe>
            <div hidden>
                
            </div> */}
        </div>
    );
}

export default MasteryPage;