import './MasteryComponent.css'
import '../../Theme.css';

import homeMasteryGraphic from '../../assets/home-mastery-graphic.svg';

import { Image } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { Tooltip } from '@mui/material';


function MasteryComponent({windowWidth}) {
    const [containerStyle, setContainerStyle] = useState({
        gap: '120px',
    });

    useEffect(() => {
        const gapCalculated = (windowWidth.windowWidth - 1440) / 2 + 120;
        const gapResponsive = gapCalculated > 240 ? 240 : gapCalculated < 20 ? 20 : gapCalculated;
        const newValue = {
            gap: `${gapResponsive}px`,
        };
        setContainerStyle(newValue);

    }, [windowWidth]);

    return (
        <div className='mastery-container' style={containerStyle}>
            <Image className='mastery-image' src={homeMasteryGraphic} alt='Mastery' />
            <div className='mastery-section-heading-container'>
                <div className='mastery-section-heading-text-container'>
                        <div className='heading-1 primary-text'>Kelas Materi Asisten Ilmiahmu</div>
                        <div className='body-1 primary-text'>
                            Asisten Ilmiahmu menyediakan bimbingan pelajaran bagi mahasiswa fakultas-fakultas kedokteran, sesuai kebutuhanmu!
                        </div>
                </div>
                <Tooltip title={<h2>Coming soon!</h2>} arrow>
                    <div className='mastery-section-heading-primary-button round-corner-8 lighter-2-fill button'>
                        <div className='body-2 white-text text-center'>Ikuti Kelasnya</div>
                    </div>
                </Tooltip>
            </div>

        </div>
    );
}

export default MasteryComponent