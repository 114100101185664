import './CredentialsLandingComponent.css';
import '../../Theme.css';

function LandingComponent() {
    return (
        <div className='credentials-landing-container lighter-3-fill'>
            <div className='heading-1 primary-text text-center align-stretch'>
                Disclaimer
            </div>
            <div className='body-1 primary-text text-center'>
                Semua hasil karya bantuan ASISTEN ILMIAHMU sepenuhnya menjadi hak dan tanggung jawab dari KLIEN, sehingga dampak apapun dari penggunaan karya tersebut oleh KLIEN bukanlah tanggung jawab dari ASISTEN ILMIAHMU.
            </div>
        </div>
    );
}

export default LandingComponent;