import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import './App.css';
import Layout from './components/LayoutComponent';
import ServicesPage from './pages/ServicesPage/ServicesPage';
import HomePage from './pages/HomePage/HomePage';
import MasteryPage from './pages/MasteryPage/MasteryPage';
import CredentialsPage from './pages/CredentialsPage/CredentialsPage';
import SplashPage from './pages/SplashPage/SplashPage';
import BlogPage from './pages/BlogPage/BlogPage';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage/TermsAndConditionsPage';


function App() {
  return (
    <Router future={{ v7_startTransition: true }}>
      <Routes>
        <Route path="/" element={<Layout />} >
          <Route index element={<HomePage />} />
          {/* <Route path="about" element={<About />} /> */}
        </Route>
        <Route path="/home" element={<Layout />} >
          <Route index element={<HomePage />} />
          {/* <Route path="about" element={<About />} /> */}
        </Route>

        <Route path="/services" element={<Layout />} >
          <Route index element={<ServicesPage />} />
          {/* <Route path="details/:id" element={<ProductDetails />} /> */}
        </Route>

        <Route path="/mastery" element={<Layout />} >
          <Route index element={<MasteryPage />} />
        </Route>

        <Route path="/credentials" element={<Layout />} >
          <Route index element={<CredentialsPage />} />
        </Route>

        <Route path="/blog" element={<Layout />} >
          <Route index element={<BlogPage />} />
        </Route>

        <Route path="/terms-and-conditions" element={<Layout />} >
          <Route index element={<TermsAndConditionsPage />} />
        </Route>

        <Route path="/splash" element={<Layout />} >
          <Route index element={<SplashPage />} />
        </Route>

        <Route path="*" element={<Navigate to="/splash" />} />
      </Routes>
    </Router>
  );
}

export default App;
