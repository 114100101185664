import './HomePage.css';
import '../../Theme.css';

import LandingComponent from '../../components/HomePage/LandingComponent';
import ClientComponent from '../../components/HomePage/ClientComponent';
import ServicesComponent from '../../components/HomePage/ServicesComponent';
import TestimonialComponent from '../../components/HomePage/TestimonialComponent';
import CredentialsComponent from '../../components/HomePage/CredentialsComponent';
import MasteryComponent from '../../components/HomePage/MasteryComponent';
import BlogComponent from '../../components/HomePage/BlogComponent';

import { useEffect, useState } from 'react';

function HomePage() {
    const [windowWidth, setWindowWidth] = useState(0);

    useEffect(() => { 
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => 
            window.removeEventListener('resize',updateDimensions);
    }, [windowWidth]);
    const updateDimensions = () => {
        const width = window.innerWidth;
        setWindowWidth(width);
    };

    return (
        <div className='white-fill'>
            <LandingComponent windowWidth={windowWidth} />
            <ClientComponent windowWidth={windowWidth} />
            <ServicesComponent windowWidth={windowWidth} />
            <TestimonialComponent windowWidth={windowWidth} />
            <CredentialsComponent windowWidth={windowWidth} />
            <MasteryComponent windowWidth={windowWidth} />
            <BlogComponent windowWidth={windowWidth} />
        </div>
    );
}

export default HomePage;