import './ClientComponent.css'
import '../../Theme.css';

import placeholderGraphic from '../../assets/graphic-placeholder.png';

import { Image } from 'react-bootstrap';

import doctorNeutral from '../../assets/home-clients/doctor-neutral.svg';
import inpatientNurse from '../../assets/home-clients/inpatient-nurse.svg';
import doctorFemale from '../../assets/home-clients/doctor-female.svg';
import operationNurse from '../../assets/home-clients/operation-nurse.svg';
import doctorMale from '../../assets/home-clients/doctor-male.svg';
import outpatientNurse from '../../assets/home-clients/outpatient-nurse.svg';


function ClientComponent() {
    const clientLogos = [
        { source: doctorNeutral, alt: 'Doctor Neutral'},
        { source: inpatientNurse, alt: 'In-patient Nurse'},
        { source: doctorFemale, alt: 'Doctor Female'},
        { source: operationNurse, alt: 'Operation Nurse'},
        { source: doctorMale, alt: 'Doctor Male'},
        { source: outpatientNurse, alt: 'Out-patient Nurse'},
    ];
    const clientLogosDisplay = [];
    for (let i = 0; i < clientLogos.length; i++) {
        clientLogosDisplay.push(
            <Image key={i} className='client-logo' src={clientLogos[i].source} alt={clientLogos[i].alt} />
        );
    }

    return (
        <div className='client-container'>
            <div className='client-section-heading-container'>
                <div className='heading-1 primary-text text-center'>
                    Kami Paham, Kami Juga Dokter
                </div>
                <div className='body-1 primary-text text-center'>
                    Tim Asisten Ilmiahmu merupakan Dokter yang paham bahwa kehidupan TS tidak seindah asumsi masyarakat. Pembagian antara waktu pribadi dan keluarga di tengah komitmen pendidikan dan tuntutan ilmiah sering membuat TS kewalahan dan lelah. Kami telah melalui tantangan yang sama. Dan kami di sini hadir untuk membantumu.
                </div>
                <div className='client-logos-container'>
                    {clientLogosDisplay}
                </div>
            </div>
        </div>
    );
}

export default ClientComponent;