import './FooterComponent.css'
import '../Theme.css';

import logo from '../assets/logo-for-dark-bg.svg';
import whatsappIcon from '../assets/whatsapp-icon.svg';
import instagramIcon from '../assets/instagram-icon.svg';
import facebookIcon from '../assets/facebook-icon.svg';
import footerWave from '../assets/footer-wave.svg';

import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';

function FooterComponent({windowWidth, currentRoute}) {

    const pageItems = [
        {id: 1, pageRoute: 'services', pageText: 'Pilihan Jasa', enabled: true},
        {id: 2, pageRoute: 'mastery', pageText: 'Kelas Materi', enabled: false},
        {id: 3, pageRoute: 'credentials', pageText: 'Kredensial', enabled: true},
        {id: 4, pageRoute: 'blog', pageText: 'Blog', enabled: false},
    ];

    const [navContainerStyle, setNavContainerStyle] = useState({});
    const [displayWaves, setDisplayWaves] = useState(true);

    useEffect(() => {
        const width = windowWidth;
        const gapCalculated = (width - 1440) / 2 + 120;
        const gapResponsive = gapCalculated > 240 ? 240 : gapCalculated < 40 ? 40 : gapCalculated;
        const newValue = {
            gap: `${gapResponsive}px`,
        };
        setNavContainerStyle(newValue);

        const enableWaves = ['/', '/home', '/services', '/mastery', '/credentials', '/blog'];
        if (!enableWaves.includes(currentRoute)) {
            if (!/services/.test(currentRoute)) {
                setDisplayWaves(false);
            }
        } else {
            setDisplayWaves(true);
        }
    }, [windowWidth, currentRoute]);


    return (
        <div className='footer-container'>
            {displayWaves && 
                <Image className='footer-wave-container' src={footerWave} alt='Footer Wave' />
            }

            {/* <div className='footer-nav-wrapper'> */}
            <div className='footer-nav-container primary-fill' style={navContainerStyle}>
                <div className='footer-nav-copyright-container'>
                    <Link to="/home">
                        {/* <Image className='footer-nav-copyright-logo' src={logo} alt='Asisten Ilmiahmu' /> */}
                    </Link>
                    <div className='footer-nav-copyright-text-container'>
                        <div className='heading-5 white-text'>Business Inquiries</div>
                        <Link to="mailto:kantor.asistenilmiahmu@gmail.com" className='plain-link body-3 white-text'>kantor.asistenilmiahmu@gmail.com</Link>
                    </div>
                </div>
                <div className='footer-nav-links-container'>
                    <div className='footer-nav-links-sitemap-container'>
                        {pageItems.map(item => (
                            <div key={item.pageRoute}>
                                {item.enabled && 
                                // onClick={() => handlePageClick(item.pageRoute)}
                                    <div className='footer-nav-links-sitemap-option-container'>
                                        {currentRoute === '/' + item.pageRoute && 
                                            <Link to={'/' + item.pageRoute} className='plain-link no-wrap heading-5 brand-text'>{item.pageText}</Link>
                                        }
                                        {!(currentRoute === '/' + item.pageRoute) && 
                                            <Link to={'/' + item.pageRoute} className='plain-link no-wrap heading-5 white-text'>{item.pageText}</Link>
                                        }
                                    </div>
                                }
                                {!item.enabled &&
                                    <Tooltip title={<h2>Coming soon!</h2>} arrow>
                                        <div className='footer-nav-links-sitemap-option-container'>
                                            <div className='plain-link no-wrap heading-5 secondary-text'>{item.pageText}</div>
                                        </div>
                                    </Tooltip>
                                }
                            </div>
                        ))}
                    </div>
                    <div className='footer-nav-link-others-container'>
                        <div className='body-3 white-text'>Copyright © 2023 AsistenIlmiahmu</div>
                        <Link to='/terms-and-conditions' className='plain-link body-3 white-text'>Syarat dan Ketentuan</Link>
                    </div>
                </div>
                <div className='footer-nav-contact-container'>
                    <div className='footer-nav-social-links-container'>
                        <Link to='https://wa.me/6281945122333/'><Image className='footer-nav-social-links' src={whatsappIcon} alt='WhatsApp Icon' /></Link>
                        <Link to='https://instagram.com/asistenilmiahmu/'><Image className='footer-nav-social-links' src={instagramIcon} alt='Instagram Icon' /></Link>
                        <Link to='https://facebook.com/asistenilmiahmu.ID/'><Image className='footer-nav-social-links' src={facebookIcon} alt='Facebook Icon' /></Link>
                    </div>
                    <div className='heading-5 white-text'>Ikuti Kami</div>
                </div>
            </div>
            {/* </div> */}
        </div>
    );
}

export default FooterComponent;