import '../../Theme.css';
import './TermsAndConditionsLanding.css';

export default function LandingComponent() {

    // const items = [
    //     {id: 1, heading: , numbered: false, content: , specialClass: },
    //     {id: 2, heading: , numbered: '1', content: , specialClass: },
    //     {id: 3, heading: , numbered: 'a', content: , specialClass: },
    //     {id: 4, heading: , numbered: , content: , specialClass: },
    // ];

    return (
        <div className='terms-and-conditions-landing-container white-text'>
            <div className='terms-and-conditions-landing-heading-container'>
                <div className='heading-4 align-stretch text-right'>Last updated: 05/10/2023</div>
                <div className='mega-1 align-stretch text-center'>Syarat & Ketentuan</div>
                <div className='heading-3 align-stretch text-center'>
                    Dengan menggunakan jasa tim ASISTEN ILMIAHMU, maka KLIEN telah menyetujui syarat & ketentuan berikut
                </div>
            </div>
            {/* {items.map(item => (
                <div className='terms-and-conditions-landing-text-container'>
                    <div>
                        {item.heading}
                    </div>
                    <div>
                        {item.content}
                    </div>
                </div>
            ))} */}
            <div className='terms-and-conditions-landing-text-container'>
                <div className='heading-1 align-stretch'>I. HAK CIPTA DAN INTELLECTUAL PROPERTY</div>
                <div className='body-1 align-stretch text-justified'>
                    Dengan melakukan pembayaran atas biaya jasa ASISTEN ILMIAHMU untuk suatu pekerjaan, maka KLIEN memiliki hak cipta dan intellectual property atas semua hasil karya bantuan ASISTEN ILMIAHMU terkait pekerjaan tersebut. Dengan demikian, semua hak dan tanggung jawab yang berkaitan dengan hasil pekerjaan tersebut menjadi sepenuhnya milik klien. Maka dampak apapun dari penggunaan karya tersebut oleh KLIEN bukanlah hak maupun tanggung jawab dari ASISTEN ILMIAHMU.
                </div>
            </div>
            <div className='terms-and-conditions-landing-text-container'>
                <div className='heading-1 align-stretch'>II. ALUR LAYANAN ASISTEN ILMIAHMU</div>
                <ol className='body-1 align-stretch text-justified'>
                    <li>KLIEN menghubungi penyedia jasa untuk bernegosiasi terkait layanan jasa yang diinginkan.</li>
                    <li>Setelah terjadi kesepakatan, KLIEN membayar uang muka (down payment / DP) ke rekening resmi ASISTEN ILMIAHMU sesuai arahan admin.</li>
                    <li>Pekerjaan hanya akan dimulai setelah adanya kesepakatan antara kedua belah pihak dan deadline mulai terhitung setelah pembayaran DP.</li>
                    <li>Bila pekerjaan sudah selesai, penyedia jasa akan menghubungi KLIEN untuk memberikan SCREENSHOT PREVIEW HASIL PEKERJAAN dan rincian final.</li>
                    <li>KLIEN melunasi selisih tarif yang belum dibayarkan dan mengirimkan bukti pembayaran.</li>
                    <li>Penyedia jasa akan mengirimkan dokumen hasil pekerjaan kepada KLIEN.</li>
                </ol>
            </div>
            <div className='terms-and-conditions-landing-text-container'>
                <div className='heading-1 align-stretch'>II. ALUR LAYANAN ASISTEN ILMIAHMU</div>
                <ol className='body-1 align-stretch text-justified'>
                    <li>KLIEN menghubungi penyedia jasa untuk bernegosiasi terkait layanan jasa yang diinginkan.</li>
                    <li>Setelah terjadi kesepakatan, KLIEN membayar uang muka (down payment / DP) ke rekening resmi ASISTEN ILMIAHMU sesuai arahan admin.</li>
                    <li>Pekerjaan hanya akan dimulai setelah adanya kesepakatan antara kedua belah pihak dan deadline mulai terhitung setelah pembayaran DP.</li>
                    <li>Bila pekerjaan sudah selesai, penyedia jasa akan menghubungi KLIEN untuk memberikan SCREENSHOT PREVIEW HASIL PEKERJAAN dan rincian final.</li>
                    <li>KLIEN melunasi selisih tarif yang belum dibayarkan dan mengirimkan bukti pembayaran.</li>
                    <li>Penyedia jasa akan mengirimkan dokumen hasil pekerjaan kepada KLIEN.</li>
                </ol>
            </div>
            <div className='terms-and-conditions-landing-text-container'>
                <div className='heading-1 align-stretch'>III. KETENTUAN REVISI</div>
                <ol type='a' className='body-1 align-stretch text-justified'>
                    <li>Meskipun hampir tidak pernah terjadi, namun apabila ada revisi yang timbul akibat kesalahan tim ASISTEN ILMIAHMU karena tidak sesuai dengan arahan KLIEN (dilihat dari outline dan instruksi yang disepakati di awal), maka sepenuhnya akan menjadi tanggung jawab ASISTEN ILMIAHMU dengan merevisi pekerjaan tersebut TANPA dikenakan biaya tambahan. Hal tersebut akan dikonfirmasi setelah dilakukan penelusuran internal tim.</li>
                    <li>Permintaan penambahan materi yang sebelumnya tidak disampaikan, akan dianggap sebagai pesanan baru / tambahan pekerjaan dan akan dikenakan biaya sesuai dengan kategori deadline baru. Hal tersebut juga termasuk permintaan revisi tambahan dari Senior, Dosen Pembimbing atau konsulen, serta revisi yang timbul karena ada informasi yang tidak disampaikan pada kesepakatan awal.</li>
                    <li>Permintaan penambahan materi (baik karena kekurangan materi / permintaan revisi) yang sudah disampaikan sedari awal, maka akan dikenakan biaya sesuai jumlah halaman tambahan sesuai dengan kategori deadline yang lama.</li>
                    <ol type='i'>
                        <li>Jika permintaan untuk menambah kekurangan materi tidak sesuai kesepakatan awal, maka dikenakan kategori 'biaya paper'.</li>
                        <li>Jika permintaan untuk menambah kekurangan materi sesuai dengan kesepakatan awal, maka dikenakan kategori 'biaya revisi'.</li>
                    </ol>
                    <li>Mengingat variasi format dan metode pembuatan yang beragam antar pusat pendidikan, maka klien dimohon untuk menyampaikan spesifikasi dari jasa yang diinginkan, termasuk format dan ketentuan. Adanya permintaan tambahan yang tidak disampaikan dari awal akan dikenakan biaya revisi.</li>
                    <li>Durasi maksimal permintaan revisi dan atau dokumen apapun (draft / file final / ZIP file referensi / raw data / dll.) untuk pekerjaan apapun adalah 2 minggu (14 hari) dihitung dari waktu dokumen terakhir dikirimkan ke KLIEN. Selebihnya, Tim tidak bertanggung jawab atas kehilangan dokumen KLIEN untuk alasan apapun, dan permintaan lanjutan terkait permintaan tersebut akan dikenakan biaya pengerjaan dengan deadline baru. Kami mohon agar KLIEN segera menyimpan file yang kami kirimkan dengan baik.</li>
                </ol>
            </div>
            <div className='terms-and-conditions-landing-text-container'>
                <div className='heading-1 align-stretch text-center'>
                    <div>Terima kasih atas kepercayaan dan kerjasamanya.</div>
                    <div>Kami berharap dapat segera membantumu :)</div>
                </div>
                
            

            </div>
        </div>
    );
};